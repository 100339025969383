body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "GothamBold";
  src: local("GothamBold"),
   url('./fonts/Gotham-Bold.otf') format("opentype");
}

@font-face {
  font-family: "SFCompact";
  src: local("SFCompact"),
   url('./fonts/SF-Compact.ttf') format("truetype");
}